import React from "react"
import loadable from '@loadable/component'
import { Placeholder } from 'semantic-ui-react'

const InnerVisConceptMap = loadable(() => import("./InnerVisConceptMap"), {
    fallback: <Placeholder fluid style={{height: '800px'}}><Placeholder.Image/></Placeholder>
})

function VisConceptMap({...props}) {
    return(
        <div>
            <InnerVisConceptMap {...props} style={{height: '800px'}}/>
        </div>
    );
}

export default VisConceptMap;