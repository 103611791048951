import React from "react"
import { Header, Icon, Breadcrumb } from "semantic-ui-react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import VisConceptMap from "../components/VisConceptMap"

const ConceptMapPage = (args) => {
  const concepts = args.data.concepts.nodes;
  const misconceptions = args.data.misconceptions.nodes;

  return <Layout>
    <SEO title="Concept Map" />
    <div style={{ marginLeft: "10px", marginRight: "10px", marginTop: "50px", marginBottom: "50px" }}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          Home
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Concept Map</Breadcrumb.Section>
      </Breadcrumb>

      <Header as="h1" dividing>
        <Icon name="lightbulb" />
        <Header.Content>
          Concept Map
          <Header.Subheader>Map of Concepts Connected by Misconceptions</Header.Subheader>
        </Header.Content>
      </Header>

      <VisConceptMap concepts={concepts} misconceptions={misconceptions} />

    </div>
  </Layout>;
}

export default ConceptMapPage

export const pageQuery = graphql`
  query {
    concepts: allFile(
      filter: {absolutePath: {glob: "**/src/data/concepts/*.md"}},
      sort: {fields: name}
    ) {
      nodes {
        name
      }
    }
    misconceptionConcepts: allMdx {
      group(field: frontmatter___concepts) {
        fieldValue
        totalCount
      }
    }
    misconceptions: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/data/misconceptions/**/index.md" }
      }
      sort: { fields: fields___name, order: ASC }
    ) {
      nodes {
        fields {
          slug
          name
          pl
        }
        frontmatter {
          status
          concepts
          shortDescription
        }
      }
    }
  }
`
